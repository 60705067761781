var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"search-t"},[_c('van-search',{ref:"search",attrs:{"show-action":"","shape":"round","placeholder":"请输入搜索关键词","autofocus":true},on:{"search":_vm.onSearch,"cancel":_vm.onCancel},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1),(true)?[_c('van-list',{staticClass:"goodsList",attrs:{"finished":_vm.finished,"finished-text":""},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"list flex fd-c"},[_vm._l((_vm.list),function(goods,index){return [(index%2==0)?_c('div',{key:index,staticClass:"loveGoods",on:{"click":function($event){return _vm.$router.push({
                          path: '/home/details',
                          query: { id: goods.id },
                      })}}},[(!goods.plan)?_c('div',{staticClass:"goods-item item"},[_c('img',{staticClass:"goodsImg",attrs:{"src":goods.pic,"alt":""}}),_c('div',{staticClass:"brand"},[_vm._v(_vm._s(goods.brandName))]),_c('div',{staticClass:"name lines"},[_vm._v(_vm._s(goods.name))]),_c('div',{staticClass:"money-box flex jc-sb"},[_c('div',{staticClass:"money flex ai-c"},[_c('div',{staticClass:"t1"},[_vm._v("¥"+_vm._s(goods.price))]),(goods.priceLining)?_c('div',{staticClass:"t2"},[_vm._v("¥"+_vm._s(goods.priceLining))]):_vm._e()]),_c('img',{staticClass:"cart",attrs:{"src":"/img/cart.png","alt":""}})]),_c('div',{staticClass:"explain line"},[_vm._v(_vm._s(goods.keywords))])]):_vm._e(),(goods.plan)?_c('img',{staticClass:"bigImg",attrs:{"src":goods.plan,"alt":""}}):_vm._e()]):_vm._e()]})],2),_c('div',{staticClass:"list flex fd-c ai-fd"},[_vm._l((_vm.list),function(goods,index){return [(index%2!=0)?_c('div',{key:index,staticClass:"loveGoods",on:{"click":function($event){return _vm.$router.push({
                          path: '/home/details',
                          query: { id: goods.id },
                      })}}},[(!goods.plan)?_c('div',{staticClass:"goods-item item"},[_c('img',{staticClass:"goodsImg",attrs:{"src":goods.pic,"alt":""}}),_c('div',{staticClass:"brand"},[_vm._v(_vm._s(goods.brandName))]),_c('div',{staticClass:"name lines"},[_vm._v(_vm._s(goods.name))]),_c('div',{staticClass:"money-box flex jc-sb"},[_c('div',{staticClass:"money flex ai-c"},[_c('div',{staticClass:"t1"},[_vm._v("¥"+_vm._s(goods.price))]),(goods.priceLining)?_c('div',{staticClass:"t2"},[_vm._v("¥"+_vm._s(goods.priceLining))]):_vm._e()]),_c('img',{staticClass:"cart",attrs:{"src":"/img/cart.png","alt":""}})]),_c('div',{staticClass:"explain line"},[_vm._v(_vm._s(goods.keywords))])]):_vm._e(),(goods.plan)?_c('img',{staticClass:"bigImg",attrs:{"src":goods.plan,"alt":""}}):_vm._e()]):_vm._e()]})],2)])]),(_vm.list.length==0&&!_vm.isLogo)?_c('div',{staticClass:"empty flex fd-c ai-c jc-c"},[_c('img',{attrs:{"src":"/img/icon-empty1.png","alt":""}}),_vm._v(" 没有搜索到相关产品 ")]):_vm._e(),(_vm.list.length==0&&_vm.isLogo)?_c('div',{staticClass:"empty flex fd-c ai-c jc-c"},[_c('img',{attrs:{"src":"/img/icon-logo.png","alt":""}}),_vm._v(" 请输入您要搜索的商品 ")]):_vm._e(),_c('v-loveGoodsList',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.length==0),expression:"list.length==0"}]})]:_vm._e(),(false)?[_c('div',{staticClass:"empty-box"},[_c('div',{staticClass:"empty-item"},[_c('div',{staticClass:"empty-t flex ai-c jc-sb"},[_vm._v(" 最近搜索 "),_c('van-icon',{attrs:{"name":"delete-o","size":".36rem","color":"rgba(32, 32, 32, 1)"}})],1),_vm._m(0)]),_vm._m(1)])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"words-list flex ai-c fw-w"},[_c('div',{staticClass:"words-item"},[_vm._v("玉泽")]),_c('div',{staticClass:"words-item"},[_vm._v("电动牙刷")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty-item"},[_c('div',{staticClass:"empty-t flex ai-c jc-sb"},[_vm._v(" 热门搜索 ")]),_c('div',{staticClass:"words-list flex ai-c fw-w"},[_c('div',{staticClass:"words-item words-active"},[_vm._v("新手专享")]),_c('div',{staticClass:"words-item"},[_vm._v("防晒衣")]),_c('div',{staticClass:"words-item"},[_vm._v("面膜")]),_c('div',{staticClass:"words-item"},[_vm._v("电动牙刷")]),_c('div',{staticClass:"words-item"},[_vm._v("眼霜")]),_c('div',{staticClass:"words-item"},[_vm._v("口红")])])])
}]

export { render, staticRenderFns }