<template>
  <div class="loveGoodsList">
    <div class="love-title" v-if="!isHide">猜你喜欢</div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
        <div class="goodsList flex">
          <div class="list flex fd-c">
              <template v-for="(item, index) in goodsList">
                <v-loveGoods v-if="index%2==0" :goods="item" :key="index" />
              </template>
          </div>
          <div class="list flex fd-c ai-fd">
              <template v-for="(item, index) in goodsList">
                  <v-loveGoods v-if="index%2!=0" :goods="item" :key="index" />
              </template>
          </div>
        </div>
    </van-list>
  </div>
</template>
 
<script>
import loveGoods from './loveGoods'
import { goodsTag, goodsPage } from '../api'
export default {
  name: "",
  components: {'v-loveGoods': loveGoods},
  props: ["isHide"],
  data() {
    return {
      goodsList: [],
      current: 1,
      loading: false,
      finished: true,
    };
  },
  created() {
    this.goodsTag()
  },
  methods: {
    goodsTag() {
      goodsPage({
        order: 0,
        current: this.current,
        size: 20,
      }).then(res=>{
        var list = res.data.data.records
        // 加载状态结束
        this.loading = false;
        this.current++
        if (list.length==0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        for (var item of list) {
          item.pic = item.pic?item.pic.split(',')[0]:[]
          item.goodsId = item.id
          this.goodsList.push(item)
        }
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.goodsTag()
    },
  },
};
</script>
 
<style scoped>
.goodsList {
  width: 7.1rem;
  margin-left: 0.2rem;
}
.goodsList .list{
    flex: 1;
}
.love-title{
  width: 100%;
  text-align: center;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-top: .5rem;
  margin-bottom: .3rem;
}
</style>