<template>
  <div class="wrapper">
    <div class="search-t">
      <van-search
        v-model="keywords"
        ref="search"
        show-action
        shape="round"
        placeholder="请输入搜索关键词"
        :autofocus="true"
        @search="onSearch"
        @cancel="onCancel"
      />
    </div>
    <template v-if="true">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad" 
        class="goodsList">
        <div class="flex">
          <div class="list flex fd-c">
              <template v-for="(goods, index) in list">
                <div
                     v-if="index%2==0"
                    class="loveGoods"
                    @click="
                        $router.push({
                            path: '/home/details',
                            query: { id: goods.id },
                        })
                    "
                    :key="index"
                >
                    <div v-if="!goods.plan" class="goods-item item">
                        <img class="goodsImg" :src="goods.pic" alt="" />
                        <div class="brand">{{ goods.brandName }}</div>
                        <div class="name lines">{{ goods.name }}</div>
                        <div class="money-box flex jc-sb">
                            <div class="money flex ai-c">
                                <div class="t1">¥{{ goods.price }}</div>
                                <div class="t2" v-if="goods.priceLining">¥{{ goods.priceLining }}</div>
                            </div>
                            <img class="cart" src="/img/cart.png" alt="" />
                        </div>
                        <div class="explain line">{{ goods.keywords }}</div>
                    </div>
                    <img
                        class="bigImg"
                        v-if="goods.plan"
                        :src="goods.plan"
                        alt=""
                    />
                </div>
              </template>
          </div>
          <div class="list flex fd-c ai-fd">
              <template v-for="(goods, index) in list">
                  <div
                     v-if="index%2!=0"
                    class="loveGoods"
                    @click="
                        $router.push({
                            path: '/home/details',
                            query: { id: goods.id },
                        })
                    "
                    :key="index"
                >
                    <div v-if="!goods.plan" class="goods-item item">
                        <img class="goodsImg" :src="goods.pic" alt="" />
                        <div class="brand">{{ goods.brandName }}</div>
                        <div class="name lines">{{ goods.name }}</div>
                        <div class="money-box flex jc-sb">
                            <div class="money flex ai-c">
                                <div class="t1">¥{{ goods.price }}</div>
                                <div class="t2" v-if="goods.priceLining">¥{{ goods.priceLining }}</div>
                            </div>
                            <img class="cart" src="/img/cart.png" alt="" />
                        </div>
                        <div class="explain line">{{ goods.keywords }}</div>
                    </div>
                    <img
                        class="bigImg"
                        v-if="goods.plan"
                        :src="goods.plan"
                        alt=""
                    />
                </div>
              </template>
          </div>
          </div>
      </van-list>
      <div class="empty flex fd-c ai-c jc-c" v-if="list.length==0&&!isLogo">
          <img src="/img/icon-empty1.png" alt="">
          没有搜索到相关产品
      </div>
      <div class="empty flex fd-c ai-c jc-c" v-if="list.length==0&&isLogo">
          <img src="/img/icon-logo.png" alt="">
          请输入您要搜索的商品
      </div>
      <v-loveGoodsList v-show="list.length==0" />
    </template>
    <template v-if="false">
      <div class="empty-box">
        <div class="empty-item">
          <div class="empty-t flex ai-c jc-sb">
            最近搜索
            <van-icon name="delete-o" size=".36rem" color="rgba(32, 32, 32, 1)" />
          </div>
          <div class="words-list flex ai-c fw-w">
            <div class="words-item">玉泽</div>
            <div class="words-item">电动牙刷</div>
          </div>
        </div>
        <div class="empty-item">
          <div class="empty-t flex ai-c jc-sb">
            热门搜索
          </div>
          <div class="words-list flex ai-c fw-w">
            <div class="words-item words-active">新手专享</div>
            <div class="words-item">防晒衣</div>
            <div class="words-item">面膜</div>
            <div class="words-item">电动牙刷</div>
            <div class="words-item">眼霜</div>
            <div class="words-item">口红</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
import { goodsPage } from "../../../api";
export default {
  name: 'search',
  components: {
    'v-loveGoodsList':loveGoodsList
  },
  props: {},
  data() {
    return {
      keywords: "",
      list: [],
      current: 1,
      loading: false,
      finished: true,
      timer: null,
      isLogo: true
    };
  },
  activated () {
      var searchRefresh = localStorage.getItem('searchRefresh')
      if (!searchRefresh) {
        
        localStorage.setItem('searchRefresh', 1)
        this.finished = true
        this.keywords = ""
        this.current = 1
        this.list = []
        this.loading = false
        this.timer = null
        this.isLogo = true
        setTimeout(()=>{
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },100)
      }
      
  },
  watch: {
    keywords() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.isLogo = false
        this.current = 1
        this.list = []
        if (this.keywords!='') {
          this.goodsPage()
        }
        
      }, 1000);
    }
  },
  computed: {},
  methods: {
    onSearch(val) {},
    onCancel() {
      this.$router.go(-1)
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.keywords!='') {
        this.goodsPage()
      } else {
        this.loading = false;
      }
        
    },
    goodsPage() {
        this.$toast.loading({
          duration: 0,
          forbidClick: true
        })
        goodsPage({
            current: this.current,
            size: 20,
            goodsCategoryList: '',
            keywords:this.keywords,
            order: 0
        }).then((res) => {
          var data = res.data.data.records
          // 加载状态结束
          this.loading = false;
          this.current++
          if (data.length==0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          for (var item of data) {
            console.log(item);
            item.pic = item.pic.split(',')[0]
          }
          this.list = this.list.concat(data)
          console.log(res.data);
        }).finally(()=>{
          this.$toast.clear()
        });
    },
  },
  created (){
    localStorage.setItem('searchRefresh', 1)
    
  },
  mounted() {
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords
    }
  },
};
</script>
<style scoped>
.search-t {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-bottom: 0.02rem solid rgba(244, 244, 244, 1);
}
.list {
  margin-top: 1rem;
}
.empty{
  width: 100%;
  height: 2.84rem;
  background-color: #fff;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: 500;
  color: #BABABA;
}
.empty img{
  width: 1.86rem;
  height: 1.48rem;
  margin-bottom: .34rem;
} 
.empty-box{
  width: 100%;
  background-color: #fff;
  margin-top: 1rem;
  overflow: hidden;
}
.empty-box .empty-item{
  margin-top: .7rem;
}
.empty-box .empty-t{
  font-size: .30rem;
  font-family: PingFang;
  font-weight: 500;
  color: #282828;
  padding-right: .2rem;
  padding-left: .26rem;
  box-sizing: border-box;
}
.words-list{
  margin-top: .1rem;
}
.words-list .words-item{
  height: .56rem;
  line-height: .56rem;
  padding: 0 .28rem;
  background: #F9F9F9;
  border-radius: .10rem;
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #A0A0A0;
  margin: 0.2rem .26rem 0;
}
.words-list .words-active{
  background: #FDF6F4;
  color: #F7532C;
}
.goodsList {
  width: 7.1rem;
  margin-left: 0.2rem;
  margin-top: .2rem;
}
.goodsList .list{
    flex: 1;
}
.goods-item {
  position: relative;
  width: 3.4rem;
  border-radius: 0.1rem;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: .26rem;
  padding-bottom: .3rem;
}
.goods-item .name {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.2rem;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: #282828;
  line-height: 0.36rem;
  margin-top: 0.3rem;
}
.goods-item .goodsImg {
  width: 100%;
  height: 3.4rem;
  object-fit: cover;
}
.list .bigImg{
  width: 3.4rem;
  height: 5.5rem;
  margin-bottom: .26rem;
}
.money-box {
  margin-top: .1rem
}
.money-box .money .t1{
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #F35E3A;
  margin-left: .2rem
}
.money-box .money .t2{
  font-size: .28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #909090;
  text-decoration:line-through;
  margin-left: .1rem;
}
.money-box .cart{
  width: .56rem;
  height: .56rem;
  margin-right: .1rem
}
.explain{
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.2rem;
  font-size: .22rem;
  font-family: PingFang;
  font-weight: 500;
  color: #F35E3A;
  margin-top: .1rem;
}
.brand{
  position: absolute;
  left: .1rem;
  top: 3.3rem;
  padding: 0 .1rem;
  line-height: .3rem;
  background: #F0C495;
  border-radius: .15rem;
  font-size: .20rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
}
</style>