<template>
    <div class="loveGoods" @click="Click">
        <div v-if="!goods.plan" class="goods-item item">
            <!-- <img class="goodsImg" :src="goods.pic" alt="" /> -->
            <van-image class="goodsImg" fit="cover" lazy-load :src="goods.pic" />
            <div class="brand">{{ goods.brandName }}</div>
            <div class="name lines">{{ goods.name }}</div>
            <div class="money-box flex jc-sb">
                <div class="money flex ai-c">
                    <div class="t1">¥{{ goods.price }}</div>
                    <div class="t2">¥{{ goods.priceLining }}</div>
                </div>
                <img class="cart" src="/img/cart.png" alt="" />
            </div>
            <div class="explain line">{{ goods.keywords }}</div>
        </div>
        <img class="bigImg" v-if="goods.plan" :src="goods.plan" alt="" />
    </div>
</template>

<script>
export default {
    components: {},
    props: ["goods"],
    data() {
        return {};
    },
    watch: {},
    computed: {},
    methods: {
        Click() {
            this.$router.push({
                path: "/home/details",
                query: { id: this.goods.goodsId },
            });
        },
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
.goods-item {
    position: relative;
    width: 3.4rem;
    border-radius: 0.1rem;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 0.26rem;
    padding-bottom: 0.3rem;
}
.goods-item .name {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.2rem;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    line-height: 0.38rem;
    min-height: 0.7rem;
    margin-top: 0.3rem;
}
.goods-item .goodsImg {
    width: 100%;
    height: 3.4rem;
    object-fit: cover;
}
.list .bigImg {
    width: 3.4rem;
    height: 5.5rem;
    margin-bottom: 0.26rem;
}
.money-box {
    margin-top: 0.1rem;
}
.money-box .money .t1 {
    font-size: 0.36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f35e3a;
    margin-left: 0.2rem;
}
.money-box .money .t2 {
    font-size: 0.28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #909090;
    text-decoration: line-through;
    margin-left: 0.1rem;
}
.money-box .cart {
    width: 0.56rem;
    height: 0.56rem;
    margin-right: 0.1rem;
}
.explain {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.2rem;
    font-size: 0.22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f35e3a;
    margin-top: 0.1rem;
}
.brand {
    position: absolute;
    left: 0.1rem;
    top: 3.3rem;
    padding: 0 0.1rem;
    line-height: 0.3rem;
    background: #f0c495;
    border-radius: 0.15rem;
    font-size: 0.2rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
}
</style>